<template>
  <v-app>
    <v-container class="fill-height">
    <v-card width="400" class="mx-auto mt-5">
      <v-card-title class="pb-0">
        <v-img src="@/assets/logo.png"/>
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="login">
          <v-text-field
              label="Username"
              prepend-icon="mdi-account-circle"
              v-model="username"
          />
          <v-text-field
              :type="showPassword ? 'text' : 'password'"
              label="Password"
              prepend-icon="mdi-lock"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              v-model="password"
          />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="success" disabled>Register</v-btn>
        <v-spacer/>
        <v-btn type='submit' @click='login' color="primary">Login</v-btn>
      </v-card-actions>
    </v-card>
    </v-container>
  </v-app>
</template>

<script>

export default {
  name: "Login",
  data: () => ({
    username: null,
    password: null,
    showPassword: false
  }),
  methods: {
    login() {
      this.$store.dispatch('login', {username: this.username, password: this.password})
          .then(() => {
            this.$router.push({name: 'dashboard'})
          })
          .catch((error) => {
            this.$toasted.error(error).goAway(2000)
          })
    }
  },
  created() {
    this.$store.commit('SET_TOKEN', null)
  }
}
</script>

<style scoped>

</style>
